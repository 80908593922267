import React, { Component } from 'react';

class Logout extends Component {
    render() {
        return (
            <div><h3>(Log out procedure implemented here; SSOL/CUIT deauthorize, remove cookies, remove session key, etc.)</h3></div>
        );
    }
}

export default Logout;
