import React, { Component } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import Overview from './academics/Overview';
import Registration from './academics/Registration';
import Schedule from './academics/Schedule';

const DARButton = <Button compact color="blue">View Degree Audit Report</Button>;

const DARModal = () => (
    <Modal trigger={DARButton}
           header='Degree Audit Report'
    >
      <Modal.Description>
        <div className="dar-flex">
          <span>Prepared: 06/07/2020 - 11:11</span>
          <Button compact color="blue">Generate PDF</Button>
        </div>
        <div className="dar-flex">
          <span><b>Program Code: </b>ENUNDC</span>
          <span>
            <b>Graduation Date: </b>**/**/**
            <br />
            <b>Catalog Year: </b>2020
          </span>
        </div>
        <hr />
        <div className="warning">
          At least one requirement has not been satisfied.
        </div>
        <hr />
        <div className="grey">
          This document is a guide for advisement.
          Your advisor is the final authority on whether requirements are met. <br/><br/>

          For questions regarding AP & transfer credit, and first 2 year requirement exemptions,
          contact your <b>class center</b>.  <br/>
          For questions regarding your major, and exceptions for major requirements,
          contact your <b>departmental advisor</b>.  <br/><br/>

          A key to abbreviations in this report appears at the end.
        </div>
        <hr />
        <div>
          <h2>Non-Technical Requirements</h2>
          <h5 className="red">Not met:</h5>
          <ul>
            <li>University Writing</li>
            <ul>
              <li>Select from: ENGL1007C, 1010, 1014, 1015, 1011, 1012, 1013, 1020</li>
            </ul>
            <li>Economics</li>
            <li>Humanities</li>
            <ul>
              <li>Select from: HUMA1121, 1123</li>
            </ul>
          </ul>
        </div>
        <div>
          <h2>Comtemporary Civilization Non-Technical</h2>
          <h5 className="red">Not met:</h5>
          <ul>
            <li>Contemporary Civilization</li>
            <ul>
              <li>Select from: COCI1101, 1102</li>
            </ul>
            <em>or</em><br/><br/>
            <li>Masterpieces of Western Literature</li>
            <ul>
              <li>Select from: HUMA1001, 1002</li>
            </ul>
          </ul>
        </div>
      </Modal.Description>
    </Modal>
)


class Academics extends Component {
    render() {
        return (
            <div id="academics">
              <Overview />
              <div className="portal">
                <div className="portal-header">
                  <h1>Academics</h1>
                  { DARModal() }
                </div>
                <Registration />
                <Schedule />
              </div>
            </div>
        );
    }
}

export default Academics;
